import { NavItem } from '@/types';

export const navItemsSeller: NavItem[] = [
  {
    title: 'Listing',
    href: '/listing',
    icon: 'layoutGrid',
    label: 'Listing',
  },
  {
    title: 'Offres',
    href: '/offers',
    icon: 'tags',
    label: 'Offres',
  }
];

export const navItemsPartner: NavItem[] = [
  {
    title: 'Demandes',
    href: '/partner/demands',
    icon: 'tags',
    label: 'Demandes',
  },
  {
    title: 'Offres',
    href: '/partner/offers',
    icon: 'shoppingCart',
    label: 'Offres',
  },
];

export const naveItemsProfileSeller: NavItem[] = [
  {
    title: 'personal_information',
    href: '/personal-information',
    icon: undefined,
    label: 'personal_information',
  },
  {
    title: 'payment_method',
    href: '/bank-information',
    icon: undefined,
    label: 'payment_method',
  },
  {
    title: 'address',
    href: '/address',
    icon: undefined,
    label: 'address',
  },
  {
    title: 'legal_information',
    href: '/legal',
    icon: undefined,
    label: 'legal_information',
  },
];

export const naveItemsProfilePartner: NavItem[] = [
  {
    title: 'personal_information',
    href: '/partner/personal-information',
    icon: undefined,
    label: 'personal_information',
  },
  {
    title: 'payment_method',
    href: '/partner/bank-information',
    icon: undefined,
    label: 'payment_method',
  },
  {
    title: 'address',
    href: '/partner/address',
    icon: undefined,
    label: 'address',
  },
  {
    title: 'legal_information',
    href: '/partner/legal',
    icon: undefined,
    label: 'legal_information',
  },
];
