import { useEffect } from 'react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import {
  useGetKycSellerQuery,
  useGetReferencesSellerQuery,
  usePostKycSellerMutation,
} from '@/api/services/Seller/onboarding';
import {
  ONBOARDING_STATUS_SELLER,
  TOnboardingForm,
  ZOnboardingFormSchema,
} from '@/types/Seller/onboarding';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useToast } from '@/components/ui/use-toast';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Separator } from '@/components/ui/separator';
import { AddressAutofill } from '@mapbox/search-js-react';
import { useGetMeSellerQuery } from '@/api/services/Seller/me';
import { Loader2 } from 'lucide-react';
import Phone from '@/components/ui/phone-input';

export function OnboardingForm() {
  const { t } = useTranslation();
  const { toast } = useToast();
  const navigate = useNavigate();

  const { data: onboardingInformation } = useGetReferencesSellerQuery();
  const { data: personalInformation } = useGetMeSellerQuery();
  const { data: onboarding } = useGetKycSellerQuery();
  const [postKyc, { isLoading: isPostingKyc }] = usePostKycSellerMutation();

  const form = useForm<TOnboardingForm>({
    resolver: zodResolver(ZOnboardingFormSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      birthDate: undefined,
      birthCity: '',
      birthCountry: '',
      nationality: '',
      jobType: '',
      revenue: '',
      phone: '',
      shippingStreet: '',
      shippingCity: '',
      shippingZipcode: '',
      shippingCountry: '',
      holderName: '',
      iban: '',
      bic: '',
      proofOfBankAccount: '',
      frontOfIdentityCard: '',
      backOfIdentityCard: '',
    },
  });
  const { setValue } = form;

  useEffect(() => {
    if (personalInformation && onboardingInformation) {
      setValue('firstName', personalInformation.firstName);
      setValue('lastName', personalInformation.lastName);
      setValue('email', personalInformation.email);
      setValue('phone', personalInformation.phone || '');
      if (
        personalInformation.kycStatus === ONBOARDING_STATUS_SELLER.REJECTED &&
        onboarding
      ) {
        setValue('revenue', onboarding.revenue);
        setValue('jobType', onboarding.jobType);
        setValue('birthDate', onboarding.birthDate);
        setValue('birthCity', onboarding.birthCity);
        setValue('birthCountry', onboarding.birthCountry);
        setValue('nationality', onboarding.nationality);
        setValue('shippingStreet', onboarding.shippingStreet);
        setValue('shippingCity', onboarding.shippingCity);
        setValue('shippingZipcode', onboarding.shippingZipcode);
        setValue('shippingCountry', onboarding.shippingCountry);
        setValue('phone', onboarding.phone);
        setValue('iban', onboarding.iban);
        setValue('bic', onboarding.bic);
        setValue('holderName', onboarding.holderName);
      }
    }
  }, [personalInformation, onboarding, onboardingInformation]);

  const onSubmit = async (values: TOnboardingForm) => {
    values.jobType = Number(values.jobType);
    values.revenue = Number(values.revenue);
    values.birthDate = format(values.birthDate, 'yyyy-MM-dd');
    values.bic = values.bic.toUpperCase().replace(/\s/g, '');
    await postKyc(values)
      .unwrap()
      .then(() => {
        toast({
          variant: 'success',
          title: t('kyc_submission'),
        });
        navigate('/dashboard');
      })
      .catch(error => {
        console.error(error);
        toast({
          variant: 'destructive',
          title: t('an_error_occurred'),
        });
      });
  };

  const onChangeFile = (file: File, type: keyof TOnboardingForm) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setValue(type, reader.result as string);
    };
    reader.onerror = error => {
      console.error('Error: ', error);
    };
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
        <div className="mb-6 mt-6">
          <div>
            <h3 className="text-lg font-medium">{t('your_personal_information')}</h3>
          </div>
          <Separator />
        </div>
        <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <div className="grid gap-1">
                    <FormLabel>{t('firstname')}</FormLabel>
                    <FormControl>
                      <Input {...field} disabled />
                    </FormControl>
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
          </div>
          <div>
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <div className="grid gap-1">
                    <FormLabel>{t('lastname')}</FormLabel>
                    <FormControl>
                      <Input {...field} disabled />
                    </FormControl>
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
          </div>
          <div>
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <div className="grid gap-1">
                    <FormLabel>{t('email')}</FormLabel>
                    <FormControl>
                      <Input {...field} disabled />
                    </FormControl>
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
          </div>
          <div>
            <Phone
              name={'phone'}
              control={form.control}
              label={t('phone')}
              placeholder={''}
            />
          </div>
          <div>
            <FormField
              control={form.control}
              name="birthDate"
              render={({ field }) => (
                <FormItem>
                  <div className="grid gap-1">
                    <FormLabel>{t('birthday')}</FormLabel>
                    <FormControl>
                      <Input
                        type="date"
                        {...field}
                        value={
                          field.value instanceof Date
                            ? field.value.toISOString().split('T')[0]
                            : field.value
                        }
                      />
                    </FormControl>
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
          </div>
          <div>
            <FormField
              control={form.control}
              name="birthCity"
              render={({ field }) => (
                <FormItem>
                  <div className="grid gap-1">
                    <FormLabel>{t('birth_city')}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
          </div>
          <div>
            <FormField
              control={form.control}
              name="birthCountry"
              render={({ field }) => (
                <FormItem>
                  <div className="grid gap-1">
                    <FormLabel>{t('birth_country')}</FormLabel>
                    <FormControl>
                      <Select onValueChange={field.onChange} value={`${field.value}`}>
                        <SelectTrigger className="w-full">
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          {onboardingInformation?.countries.map(country => (
                            <SelectItem key={country.label} value={country.label}>
                              {country.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
          </div>
          <div>
            <FormField
              control={form.control}
              name="nationality"
              render={({ field }) => (
                <FormItem>
                  <div className="grid gap-1">
                    <FormLabel>{t('nationality')}</FormLabel>
                    <FormControl>
                      <Select onValueChange={field.onChange} value={`${field.value}`}>
                        <SelectTrigger className="w-full">
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          {onboardingInformation?.countries.map(country => (
                            <SelectItem key={country.label} value={country.label}>
                              {country.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
          </div>
          <div>
            <FormField
              control={form.control}
              name="jobType"
              render={({ field }) => (
                <FormItem>
                  <div className="grid gap-1">
                    <FormLabel>{t('job_type')}</FormLabel>
                    <FormControl>
                      <Select onValueChange={field.onChange} value={`${field.value}`}>
                        <SelectTrigger className="w-full">
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          {onboardingInformation?.jobTypes.map(jobType => (
                            <SelectItem key={jobType.id} value={jobType.id.toString()}>
                              {jobType.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
          </div>
          <div>
            <FormField
              control={form.control}
              name="revenue"
              render={({ field }) => (
                <FormItem>
                  <div className="grid gap-1">
                    <FormLabel>{t('revenue')}</FormLabel>
                    <FormControl>
                      <Select onValueChange={field.onChange} value={`${field.value}`}>
                        <SelectTrigger className="w-full">
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          {onboardingInformation?.revenues.map(revenue => (
                            <SelectItem key={revenue.id} value={revenue.id.toString()}>
                              {revenue.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
          </div>
          <div>
            <FormField
              control={form.control}
              name="frontOfIdentityCard"
              render={({ field: { value, onChange, ...fieldProps } }) => (
                <FormItem>
                  <FormLabel>{t('front_identity_card')}</FormLabel>
                  <FormControl>
                    <Input
                      {...fieldProps}
                      type="file"
                      accept="image/*, application/pdf"
                      title={value}
                      onChange={event => {
                        if (event.target.files) {
                          onChange(event.target.files[0]);
                          onChangeFile(event.target.files[0], 'frontOfIdentityCard');
                        }
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div>
            <FormField
              control={form.control}
              name="backOfIdentityCard"
              render={({ field: { value, onChange, ...fieldProps } }) => (
                <FormItem>
                  <FormLabel>{t('back_identity_card')}</FormLabel>
                  <FormControl>
                    <Input
                      {...fieldProps}
                      type="file"
                      accept="image/*, application/pdf"
                      title={value}
                      onChange={event => {
                        if (event.target.files) {
                          onChange(event.target.files[0]);
                          onChangeFile(event.target.files[0], 'backOfIdentityCard');
                        }
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        <div className="mt-10 mb-6">
          <div>
            <h3 className="text-lg font-medium">{t('your_address_payment_invoice')}</h3>
          </div>
          <Separator />
        </div>
        <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <FormField
              control={form.control}
              name="shippingStreet"
              render={({ field }) => (
                <FormItem>
                  <div className="grid gap-1">
                    <FormLabel>{t('address')}</FormLabel>
                    <FormControl>
                      <AddressAutofill accessToken={`${process.env.MAPBOX_API_KEY}`}>
                        <Input autoComplete="address-line1" {...field} />
                      </AddressAutofill>
                    </FormControl>
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
          </div>
          <div>
            <FormField
              control={form.control}
              name="shippingCity"
              render={({ field }) => (
                <FormItem>
                  <div className="grid gap-1">
                    <FormLabel>{t('city')}</FormLabel>
                    <FormControl>
                      <Input {...field} autoComplete="address-level2" />
                    </FormControl>
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
          </div>
          <div>
            <FormField
              control={form.control}
              name="shippingZipcode"
              render={({ field }) => (
                <FormItem>
                  <div className="grid gap-1">
                    <FormLabel>{t('postcode')}</FormLabel>
                    <FormControl>
                      <Input {...field} autoComplete="postal-code" />
                    </FormControl>
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
          </div>
          <div>
            <FormField
              control={form.control}
              name="shippingCountry"
              render={({ field }) => (
                <FormItem>
                  <div className="grid gap-1">
                    <FormLabel>{t('country')}</FormLabel>
                    <FormControl>
                      <Select onValueChange={field.onChange} value={`${field.value}`}>
                        <SelectTrigger className="w-full">
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          {onboardingInformation?.countries.map(country => (
                            <SelectItem key={country.label} value={country.label}>
                              {country.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
          </div>
        </div>

        <div className="mt-10 mb-6">
          <div>
            <h3 className="text-lg font-medium">{t('receive_payment')}</h3>
          </div>
          <Separator />
        </div>
        <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <FormField
              control={form.control}
              name="holderName"
              render={({ field }) => (
                <FormItem>
                  <div className="grid gap-1">
                    <FormLabel>{t('holder_name')}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
          </div>
          <div>
            <FormField
              control={form.control}
              name="iban"
              render={({ field }) => (
                <FormItem>
                  <div className="grid gap-1">
                    <FormLabel>{t('iban')}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
          </div>
          <div>
            <FormField
              control={form.control}
              name="bic"
              render={({ field }) => (
                <FormItem>
                  <div className="grid gap-1">
                    <FormLabel>{t('bic')}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
          </div>
          <div>
            <FormField
              control={form.control}
              name="proofOfBankAccount"
              render={({ field: { value, onChange, ...fieldProps } }) => (
                <FormItem>
                  <div className="grid gap-1">
                    <FormLabel>{t('rib')}</FormLabel>
                    <FormControl>
                      <Input
                        {...fieldProps}
                        type="file"
                        accept="image/*, application/pdf"
                        title={value}
                        onChange={event => {
                          if (event.target.files) {
                            onChange(event.target.files[0]);
                            onChangeFile(event.target.files[0], 'proofOfBankAccount');
                          }
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
          </div>
        </div>

        <div className="grid gap-1 mt-6">
          {isPostingKyc ? (
            <Button disabled variant="primary">
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              {t('submit_validation')}
            </Button>
          ) : (
            <Button type="submit" variant="primary">
              {t('submit_validation')}
            </Button>
          )}
        </div>
      </form>
    </Form>
  );
}
