interface AppConfig {
  name: string;
  github: {
    title: string;
    url: string;
  };
}

export const appConfig: AppConfig = {
  name: 'Scale',
  github: {
    title: 'Scale',
    url: '#',
  },
};

export const FEES = 1.3;

export enum TOKEN_TYPE {
  'SELLER' = 'SELLER',
  'PARTNER' = 'PARTNER',
}
