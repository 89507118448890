import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import i18n from 'i18next';
import { enUS, fr } from 'date-fns/locale';
import { TOKEN_TYPE } from '@/config/app.ts';

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

export const isPartner = localStorage.getItem('tokenType') === TOKEN_TYPE.PARTNER;

export const isSeller = localStorage.getItem('tokenType') === TOKEN_TYPE.SELLER;

export const getLocale = () => {
  if (i18n.language === 'fr') {
    return fr;
  }
  return enUS;
};

export const sanitizeBase64 = (base64Input = '') => {
  return base64Input.replace(/\s/g, '');
};
// Function to capitalize the first letter
export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Function to lowercase the first letter
export const lowercaseFirstLetter = (string: string): string => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};

export const sentenceCase = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const lowerCase = (str: string): string => {
  return str.charAt(0).toLowerCase() + str.slice(1).toLowerCase();
};

export const titleCase = (str: string): string => {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const getInitials = (name: string): string => {
  const nameArray = name.split(' ');
  const firstNameIn = nameArray[0].charAt(0).toUpperCase();
  const lastNameIn = nameArray[nameArray.length - 1].charAt(0).toUpperCase();
  return firstNameIn + lastNameIn;
};

export const formattedCurrency = (price: number) => {
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
  }).format(price);
};
