import { useTranslation } from 'react-i18next';
import { LoginForm } from '@/components/Partner/login-form';
import { Icons } from '@/components/icons';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TOKEN_TYPE } from '@/config/app.ts';

export default function LoginPartner() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const tokenType = localStorage.getItem('tokenType');

  useEffect(() => {
    if (token && tokenType === TOKEN_TYPE.PARTNER) {
      navigate('/partner/dashboard');
    }
  }, []);

  return (
    <>
      <div className="w-full grid lg:grid-cols-2 h-dvh">
        <div className="flex items-center justify-center py-12">
          <div className="absolute top-8 left-auto">
            <Icons.logo style={{ width: '140px' }} />
          </div>
          <div className="mx-auto grid lg:w-[400px] w-[350px] pt-12 gap-6">
            <div className="text-center space-y-6">
              <h1 className="text-3xl font-bold tracking-tight">
                {t('login.partner.title')}
              </h1>
              <p className="text-md text-muted-foreground">
                {t('login.partner.description')}
              </p>
            </div>
            <LoginForm />
          </div>
        </div>
        <div
          className={`hidden lg:block bg-muted align-middle bg-cover bg-[url('/backgroundPartnerLogin.png')]`}
        ></div>
      </div>
    </>
  );
}
