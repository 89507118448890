import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LoginForm } from '@/components/Seller/login-form';
import { Icons } from '@/components/icons';
import { useEffect, useState } from 'react';
import { useToast } from '@/components/ui/use-toast';
import { useLazyGetMeSellerQuery } from '@/api/services/Seller/me';
import { useLazyPostLoginQuery } from '@/api/services/Seller/login';
import Loading from '@/components/loading';
import { ONBOARDING_STATUS_SELLER } from '@/types/Seller/onboarding';
import { ToastAction } from '@/components/ui/toast';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { TOKEN_TYPE } from '@/config/app.ts';

export default function LoginSeller() {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [triggerLogin] = useLazyPostLoginQuery();
  const { t } = useTranslation();
  const { toast } = useToast();
  const navigate = useNavigate();
  const [triggerGetMe] = useLazyGetMeSellerQuery();
  const token = localStorage.getItem('token');
  const tokenType = localStorage.getItem('tokenType');

  useEffect(() => {
    if (token && tokenType === TOKEN_TYPE.SELLER) {
      navigate('/dashboard');
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    const code = searchParams.get('code');
    if (code) {
      triggerLogin(code)
        .unwrap()
        .then(res => {
          localStorage.setItem('token', res.token);
          localStorage.setItem('tokenType', TOKEN_TYPE.SELLER);
          triggerGetMe()
            .unwrap()
            .then(data => {
              setTimeout(() => {
                if (data.onBoarded) {
                  if (data.kycStatus === ONBOARDING_STATUS_SELLER.APPROVED) {
                    navigate('/listing');
                  } else {
                    navigate('/onboarding');
                  }
                } else {
                  navigate('/register');
                }
                setLoading(false);
              }, 200);
            })
            .catch(error => {
              setLoading(false);
              console.error(error);
              toast({
                variant: 'destructive',
                title: t('an_error_occurred'),
              });
              navigate('/');
            });
        })
        .catch((error: FetchBaseQueryError) => {
          setLoading(false);
          console.error(error);
          if (error.status === 401) {
            toast({
              duration: 5000,
              variant: 'destructive',
              title: t('error_whop_member'),
              action: (
                <ToastAction
                  onClick={() => window.open('https://whop.com/scale-2/')}
                  altText="Try again"
                >
                  {t('waitlist')}
                </ToastAction>
              ),
            });
          } else {
            toast({
              variant: 'destructive',
              title: t('an_error_occurred'),
            });
          }
        });
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <>
      <div className={`${loading ? 'opacity-10' : ''} w-full grid lg:grid-cols-2 h-dvh`}>
        <div className="flex items-center justify-center py-12">
          <div className="absolute top-8 left-auto">
            <Icons.logo style={{ width: '140px' }} />
          </div>
          <div className="mx-auto grid lg:w-[400px] w-[350px] pt-12 gap-6">
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-3xl font-bold tracking-tight">
                {t('login.seller.title')}
              </h1>
              <p className="text-md text-muted-foreground">
                {t('login.seller.description')}
              </p>
            </div>
            <LoginForm />
          </div>
        </div>
        <div
          className={`hidden lg:block bg-muted align-middle bg-cover bg-[url('/backgroundSellerLogin.jpg')]`}
        ></div>
      </div>
      {loading && <Loading title={t('loading')} />}
    </>
  );
}
