import * as React from 'react';
import { cn } from '@/lib/utils';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { TRegisterFormStep5, ZRegisterFormStep5Schema } from '@/types/Partner/onboarding';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetReferencesPartnerQuery,
  usePostCheckEmailMutation,
  usePostOnboardingMutation,
} from '@/api/services/Partner/onboarding';
import { useDispatch, useSelector } from 'react-redux';
import { setRegisterPartnerStep5 } from '@/api/services/app';
import { RootState } from '@/api/store';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useEffect } from 'react';
import { Loader2 } from 'lucide-react';
import { useToast } from '@/components/ui/use-toast';
import posthog from "posthog-js";

type PartnerSignFormProps = React.HTMLAttributes<HTMLDivElement>;

export function RegisterFormStep5({ className, ...props }: PartnerSignFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = useParams();
  const dispatch = useDispatch();
  const [postOnboarding, { isLoading: isPostingKyc }] = usePostOnboardingMutation();
  const [postCheckEmail, { isLoading: isPostCheckEmail }] = usePostCheckEmailMutation();
  const { toast } = useToast();

  const { data: onboardingInformation, isLoading: isLoadingOnboardingInformation } =
    useGetReferencesPartnerQuery();

  const registerPartnerStep1 = useSelector(
    (state: RootState) => state.app.seller.registerPartnerStep1,
  );
  const registerPartnerStep2 = useSelector(
    (state: RootState) => state.app.seller.registerPartnerStep2,
  );
  const registerPartnerStep3 = useSelector(
    (state: RootState) => state.app.seller.registerPartnerStep3,
  );
  const registerPartnerStep4 = useSelector(
    (state: RootState) => state.app.seller.registerPartnerStep4,
  );

  useEffect(() => {
    const data = sessionStorage.getItem('registerPartnerStep5');
    if (data) {
      try {
        const dataParse = JSON.parse(data) as TRegisterFormStep5;
        form.setValue('representativeFirstName', dataParse.representativeFirstName);
        form.setValue('representativeLastName', dataParse.representativeLastName);
        form.setValue('representativeBirthDate', dataParse.representativeBirthDate);
        form.setValue('representativeNationality', dataParse.representativeNationality);
        form.setValue('accountingReport', dataParse.accountingReport);
        form.setValue('backOfIdentityCard', dataParse.backOfIdentityCard);
        form.setValue('frontOfIdentityCard', dataParse.frontOfIdentityCard);
      } catch (e) {
        console.error(e);
      }
    }
  }, [isLoadingOnboardingInformation]);

  const form = useForm<TRegisterFormStep5>({
    resolver: zodResolver(ZRegisterFormStep5Schema),
    defaultValues: {
      representativeFirstName: '',
      representativeLastName: '',
      representativeBirthDate: undefined,
      representativeNationality: '',
      accountingReport: '',
      backOfIdentityCard: '',
      frontOfIdentityCard: '',
    },
  });
  const { setValue } = form;

  const onChangeFile = (file: File, type: keyof TRegisterFormStep5) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setValue(type, reader.result as string);
    };
    reader.onerror = error => {
      console.error('Error: ', error);
    };
  };

  const onSubmit = async (values: TRegisterFormStep5) => {
    console.info(values);
    sessionStorage.setItem('registerPartnerStep5', JSON.stringify(values));
    dispatch(setRegisterPartnerStep5(values));
    await postCheckEmail({ email: registerPartnerStep1.email })
      .unwrap()
      .then(() => {
        postOnboarding({
          ...registerPartnerStep1,
          ...registerPartnerStep2,
          ...registerPartnerStep3,
          ...registerPartnerStep4,
          ...values,
          token: token || '',
        })
          .unwrap()
          .then(() => {
            console.info('--- successfully registration');
            posthog?.identify(registerPartnerStep1.email, {
              username: registerPartnerStep1.email,
            });
            posthog?.capture('partner_successful_registration');
            navigate(`/partner/register/${token}/pending`);
          })
          .catch(err => {
            console.error(err);
            posthog?.identify(registerPartnerStep1.email, {
              username: registerPartnerStep1.email,
            });
            posthog?.capture('partner_error_registration');
            toast({
              variant: 'destructive',
              title: t('an_error_occurred'),
            });
          });
      })
      .catch(err => {
        toast({
          variant: 'destructive',
          title: t('email_already_exists'),
        });
        console.error(err);
      });
  };

  return (
    <div className={cn('grid gap-6', className)} {...props}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
          <div className="grid grid-cols-1 gap-4">
            <div>
              <FormField
                control={form.control}
                name="representativeFirstName"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('representative_first_name')}</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="representativeLastName"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('representative_last_name')}</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="representativeBirthDate"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('representative_birth_date')}</FormLabel>
                      <FormControl>
                        <Input
                          type="date"
                          {...field}
                          value={
                            field.value instanceof Date
                              ? field.value.toISOString().split('T')[0]
                              : field.value
                          }
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="representativeNationality"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('representative_nationality')}</FormLabel>
                      <FormControl>
                        <Select onValueChange={field.onChange} value={`${field.value}`}>
                          <SelectTrigger className="w-full">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            {onboardingInformation?.countries.map(country => (
                              <SelectItem key={country.label} value={country.label}>
                                {country.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="frontOfIdentityCard"
                render={({ field: { value, onChange, ...fieldProps } }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('front_identity_card')}</FormLabel>
                      <FormControl>
                        <Input
                          {...fieldProps}
                          type="file"
                          accept="image/*, application/pdf"
                          title={value}
                          onChange={event => {
                            if (event.target.files) {
                              onChange(event.target.files[0]);
                              onChangeFile(event.target.files[0], 'frontOfIdentityCard');
                            }
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="backOfIdentityCard"
                render={({ field: { value, onChange, ...fieldProps } }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('back_identity_card')}</FormLabel>
                      <FormControl>
                        <Input
                          {...fieldProps}
                          type="file"
                          accept="image/*, application/pdf"
                          title={value}
                          onChange={event => {
                            if (event.target.files) {
                              onChange(event.target.files[0]);
                              onChangeFile(event.target.files[0], 'backOfIdentityCard');
                            }
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="accountingReport"
                render={({ field: { value, onChange, ...fieldProps } }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('accounting_report')}</FormLabel>
                      <FormControl>
                        <Input
                          {...fieldProps}
                          type="file"
                          accept="image/*, application/pdf"
                          title={value}
                          onChange={event => {
                            if (event.target.files) {
                              onChange(event.target.files[0]);
                              onChangeFile(event.target.files[0], 'accountingReport');
                            }
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            {isPostingKyc || isPostCheckEmail ? (
              <Button disabled variant="primary">
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                {t('submit_validation')}
              </Button>
            ) : (
              <>
                <Button variant="primary">{t('submit')}</Button>
              </>
            )}
            <Button
              variant="outline"
              onClick={() => navigate(`/partner/register/${token}/step4`)}
              disabled={isPostingKyc || isPostCheckEmail}
            >
              {t('previous')}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
}
