import { buttonVariants } from '@/components/ui/button';
import { NavLink } from 'react-router-dom';
import { TOKEN_TYPE } from '@/config/app.ts';

export default function NoMatch() {
  const tokenType = localStorage.getItem('tokenType');
  return (
    <div className="bg-background text-foreground flex-grow flex items-center justify-center">
      <div className="space-y-4">
        <h2 className="text-8xl mb-4">404</h2>
        <h1 className="text-3xl font-semibold">Oops! Page not found</h1>
        <p className="text-sm text-muted-foreground">
          We are sorry, but the page you requested was not found
        </p>
        <NavLink
          to={tokenType === TOKEN_TYPE.PARTNER ? '/partner/dashboard' : '/dashboard'}
          className={buttonVariants()}
        >
          Back to Home
        </NavLink>
      </div>
    </div>
  );
}
