import { cn } from '@/lib/utils';
import { SearchIcon } from 'lucide-react';
import { ScrollArea } from '@/components/ui/scroll-area';
import { HitType } from '@/types/Seller/inventory';
import React from 'react';
import algoliasearch from 'algoliasearch/lite';
import { useTranslation } from 'react-i18next';
import { Configure, Hits, InstantSearch, SearchBox } from 'react-instantsearch';

const searchClient = algoliasearch(
  `${process.env.ALGOLIA_APP_ID}`,
  `${process.env.ALGOLIA_API_KEY}`,
);

interface IProps {
  setItemSelected: React.Dispatch<React.SetStateAction<HitType | undefined>>;
}

export default function Search({ setItemSelected }: IProps) {
  const { t } = useTranslation();

  const transformItems = (items: HitType[]) => {
    return items
        .filter((hit: HitType) => {
            const traits = Object.keys(hit._highlightResult.searchable_traits);
            const categories = hit.categories
            return (!(traits.includes('Hazardous Material') || categories.includes('Electronics')));
        })
      .map(item => ({
        ...item,
      }));
  };

  return (
    <InstantSearch searchClient={searchClient} indexName="products">
      <Configure analytics={false} />
      <div
        className={cn(
          'flex mb-5 items-center rounded-md border border-input bg-white pl-3 text-sm ring-offset-background',
        )}
      >
        <SearchIcon className="h-[16px] w-[16px]" />
        <SearchBox
          placeholder={t('search_product')}
          classNames={{
            root: 'w-full',
            input:
              'w-full p-2 placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
          }}
          resetIconComponent={() => <></>}
          submitIconComponent={() => <></>}
        />
      </div>
      <ScrollArea className="h-[400px] rounded-md ">
        <Hits
          hitComponent={({ hit }: { hit: HitType }) => {
            return (
              <div onClick={() => setItemSelected(hit)} className="cursor-pointer">
                <div className="flex items-stretch text-black flex">
                  <img
                    src={hit.thumbnail_url}
                    className="object-scale-down w-14"
                    alt={hit.name}
                  />
                  <p className="ml-4">
                    {hit.name} <small>{hit.ticker_symbol}</small>
                  </p>
                </div>
              </div>
            );
          }}
          classNames={{
            list: 'grid grid-cols-2 gap-4',
          }}
          transformItems={transformItems}
        />
      </ScrollArea>
    </InstantSearch>
  );
}
